import React, { useCallback, useState } from "react"
import { Link } from "gatsby"
import { OutboundLink } from "gatsby-plugin-gtag"
import { trackEvent } from "../lib/track-event"
import Hero from "../components/hero"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Icon from "../components/icon"
import Modal from "../components/modal"

const IndexPage = () => {
  const [showOfficeDepotModal, setShowOfficeDepotModal] = useState(false)
  const toggleOfficeDepotModal = useCallback(() => {
    setShowOfficeDepotModal(!showOfficeDepotModal)
    trackEvent({ label: "office_depot_register", category: "call_to_action" })
  }, [showOfficeDepotModal])
  const [showStaplesModal, setShowStaplesModal] = useState(false)
  const toggleStaplesModal = useCallback(() => {
    setShowStaplesModal(!showStaplesModal)
    trackEvent({ label: "staples_register", category: "call_to_action" })
  }, [showStaplesModal])
  const [showBoxedModal, setShowBoxedModal] = useState(false)
  const toggleBoxedModal = useCallback(() => {
    setShowBoxedModal(!showBoxedModal)
    trackEvent({ label: "boxed_register", category: "call_to_action" })
  }, [showBoxedModal])

  return (
    <Layout hideSources={true} showHero={true}>
      <SEO title="NJ PPE Access Program" />

      <Hero className="full-width bg-hero bg-cover mb-12">
        <div className="container lg:max-w-4xl mx-auto p-2 pb-10">
          <p className="text-3xl sm:text-4xl max-w-3xl font-bold mb-5">
            Now Closed: Receive 65% Discount on PPE products from trusted
            retailers.
          </p>
          <p className="font-bold text-xl max-w-2xl mb-5">
            Until Friday, May 28th, 2021 small businesses and organizations under
            100 employees were able to apply for an additional 65% discount on PPE
            products. Businesses and organizations that were already approved
            for the 65% discount in November or December will NOT need to
            reapply.{" "}
          </p>
          <p className="my-5">
            <a
              className="btn btn-secondary inline-block mr-5 mb-2"
              href="#vendors"
            >
              Find a retailer
            </a>
          </p>

          <Link className="block text-white" to="#manufacturers">
            NJ-based PPE manufacturer or wholesaler?
          </Link>
          <Link className="block text-white" to="#retailers">
            Established retailer or wholesaler that sells PPE?
          </Link>
        </div>
      </Hero>

      <div className="prose max-w-none mb-8" id="vendor-links">
        <h2 id="vendors">Approved online retailers</h2>
        <p>
          Register with the retailers below to get access to discounted PPE
          products.
        </p>
      </div>

      <div className="grid sm:grid-cols-3 gap-5 mb-12 justify-center">
        <div className="bg-backgroundHighlight flex flex-col items-center text-center border rounded-md text-black overflow-hidden max-w-md">
          <div className="w-full">
            <Icon name="office-depot" />
          </div>
          <div className="p-6">
            <p className="mb-4 mt-8">
              Office Depot is no longer accepting applications.
            </p>
          </div>
        </div>
        <div className="bg-backgroundHighlight flex flex-col items-center text-center border rounded-md text-black overflow-hidden max-w-md">
          <div className="w-full">
            <Icon name="boxed" />
          </div>
          <div className="p-6">
            <p className="mb-4 mt-8">
              Boxed is no longer accepting applications.
            </p>
          </div>
        </div>
        <div className="bg-backgroundHighlight flex flex-col items-center text-center border rounded-md text-black overflow-hidden max-w-md">
          <div className="w-full">
            <Icon name="staples" />
          </div>
          <div className="p-6">
            <p className="mb-4 mt-8">
              Staples is no longer accepting applications.
            </p>
          </div>
        </div>
      </div>

      <div className="alert alert-primary" role="alert">
        <p>
          <strong>Note on product availability</strong>
        </p>
        <p>
          While the retailers in our program are excited to share a range of
          PPE, we are aware there is a national shortage of many PPE items.
          Thus, occassionally, there might be a limited stock of supplies. These
          are the known stock-outs across our vendors:
          <p>Office Depot: currently out of stock on medical gloves</p>
        </p>
      </div>

      <Hero className="full-width bg-ppe bg-cover">
        <div className="container lg:max-w-4xl mx-auto p-2 py-10">
          <p className="font-bold text-xl max-w-2xl">
            Estimate your business’s PPE needs with an interactive PPE Planning
            ang Guidance Tool
          </p>
          <p className="mt-5">
            <Link
              to="/ppe_needs"
              className="btn btn-secondary inline-block mr-5 mb-2"
            >
              Get started now
            </Link>
          </p>
        </div>
      </Hero>

      <div className="full-width bg-backgroundHighlight pt-16 px-5 mb-8">
        <div className="prose max-w-4xl mx-auto">
          <h2 id="about">NJ PPE Access Program</h2>

          <p>
            The New Jersey Personal Protective Equipment (PPE Access Program) is
            an innovative public-private partnership that helps businesses and
            non-profits protect the health and safety of their employees and
            customers. Through the program, all NJ based businesses and
            non-profits are eligible for at least 10% discounts on PPE purchases
            made through approved online retailers.{" "}
            <Link to="#vendor-links">Click here to get started</Link>
            .​
          </p>

          <p>
            All organizations can also utilize a free online PPE planning tool
            to estimate how much PPE they will need in the coming months.{" "}
            <Link to="/ppe_needs">Click here to estimate your PPE needs</Link>
            .​
          </p>
          <p>
            <strong className="text-primary">Want to learn more?</strong> Check
            out our{" "}
            <a href="/pdfs/NJEDA_PPE_Program_FAQs-v2.pdf">
              Frequently Asked Questions
            </a>
          </p>
        </div>
        <div className="py-8 mx-auto max-w-2xl center">
          <iframe
            src="https://player.vimeo.com/video/480895900"
            width="640"
            height="360"
            style={{ maxWidth: "100%" }}
            frameBorder="0"
            allow="autoplay; fullscreen"
            title="Program Overview Video"
          ></iframe>
        </div>
      </div>

      <div className="prose max-w-none mb-8">
        <h2 id="manufacturers">For NJ-based Manufacturers/Wholesalers</h2>
        <p>
          As part of our ongoing efforts to support local manufacturers and
          wholesalers, we are offering incentives to retailers to purchase PPE
          that is manufactured in New Jersey or sourced from small wholesalers
          in historically underserved communities. To be included on our list of
          New Jersey based manufacturers and wholesalers, please fill out the
          form that applies to you: ​
        </p>
      </div>

      <div className="grid sm:grid-cols-3 gap-5 mb-12">
        <OutboundLink
          href="https://forms.business.nj.gov/ppe-access/manufacturers/"
          className="flex flex-col items-center text-center p-5 border rounded-md hover:shadow-lg transition-shadow duration-150 cursor-pointer no-underline text-black"
        >
          <div className="h-24 w-24 mb-6">
            <Icon name="vendor" />
          </div>{" "}
          <p className="mb-0 font-bold">Manufacturer Sign Up</p>
        </OutboundLink>
        <OutboundLink
          href="https://forms.business.nj.gov/ppe-access/wholesalers/"
          className="flex flex-col items-center text-center p-5 border rounded-md hover:shadow-lg transition-shadow duration-150 cursor-pointer no-underline text-black"
        >
          <div className="h-24 w-24 mb-6">
            <Icon name="vendor" />
          </div>
          <p className="mb-0 font-bold">Wholesaler Sign Up</p>
        </OutboundLink>
        <OutboundLink
          href="https://covid19.nj.gov/faqs/nj-information/uncategorized/nj-manufacturers-and-small-wholesalers:-information-on-how-to-access-funding-support-and-register-your-interest-in-selling-products-to-the-designated-vendors"
          className="flex flex-col items-center text-center p-5 border rounded-md hover:shadow-lg transition-shadow duration-150 cursor-pointer no-underline text-black"
        >
          <div className="h-24 w-24 mb-6">
            <Icon name="boxes" />
          </div>
          <p className="mb-0 font-bold break-words">
            Manufacturer/Wholesaler FAQ
          </p>
        </OutboundLink>
      </div>

      <div className="prose max-w-none">
        <p>
          If you have any questions, email us at{" "}
          <OutboundLink href="mailto:SmallBusinessPPE@njeda.com">
            SmallBusinessPPE@njeda.com
          </OutboundLink>
        </p>
      </div>

      <Modal onExit={toggleStaplesModal} show={showStaplesModal}>
        <h1 className="mb-5 text-staples">Staples 10% PPE Discount</h1>

        <div className="grid grid-cols-3 gap-5 items-stretch justify-between mb-8">
          <div className="max-w-xs flex flex-col items-center">
            <div className="bg-staples rounded-full text-white font-bold text-xl h-10 w-10 leading-10 inline-block text-center mb-5">
              1
            </div>
            <p>
              Register on the Staples portal by pressing "Get Started" below
            </p>
          </div>
          <div>
            <div className="bg-staples rounded-full text-white font-bold text-xl h-10 w-10 leading-10 inline-block text-center mb-5">
              2
            </div>
            <p>
              Receive registration confirmation via your email approximately 24
              hours. Please keep an eye out for a Staples email in your inbox.
            </p>
          </div>
          <div>
            <div className="bg-staples rounded-full text-white font-bold text-xl h-10 w-10 leading-10 inline-block text-center mb-5">
              3
            </div>
            <p>
              Log in, and add PPE items to your shopping cart! The 10% discount
              is already included.
            </p>
          </div>
        </div>
        <OutboundLink
          className="btn bg-staples text-white"
          href="https://go.staples.com/njppeaccess"
          target="_blank"
          rel="nofollow noopener noreferrer"
        >
          Get Started
        </OutboundLink>
      </Modal>

      <Modal onExit={toggleOfficeDepotModal} show={showOfficeDepotModal}>
        <h1 className="mb-5 text-officeDepot">Office Depot 10% PPE Discount</h1>

        <div className="grid grid-cols-3 gap-5 items-stretch justify-between mb-8">
          <div className="max-w-xs flex flex-col items-center">
            <div className="bg-officeDepot rounded-full text-white font-bold text-xl h-10 w-10 leading-10 inline-block text-center mb-5">
              1
            </div>
            <p>
              Register on the Office Depot portal below & sign in with your
              credentials.
            </p>
          </div>
          <div>
            <div className="bg-officeDepot rounded-full text-white font-bold text-xl h-10 w-10 leading-10 inline-block text-center mb-5">
              2
            </div>
            <p>
              View the “Shopping List” for PPE. All PPE prices include a 10%
              discount.
            </p>
          </div>
          <div>
            <div className="bg-officeDepot rounded-full text-white font-bold text-xl h-10 w-10 leading-10 inline-block text-center mb-5">
              3
            </div>
            <p>
              Add items to shopping cart and purchase! The 10% discount is
              already included.
            </p>
          </div>
        </div>
        <OutboundLink
          className="btn bg-officeDepot text-white"
          href="http://www.business.officedepot.com/njppeaccess"
          target="_blank"
          rel="nofollow noopener noreferrer"
        >
          Get Started
        </OutboundLink>
      </Modal>

      <Modal onExit={toggleBoxedModal} show={showBoxedModal}>
        <h1 className="mb-5 text-boxed">Boxed 10% PPE Discount</h1>

        <div className="grid grid-cols-3 gap-5 items-stretch justify-between mb-8">
          <div className="max-w-xs flex flex-col items-center">
            <div className="bg-boxed rounded-full text-white font-bold text-xl h-10 w-10 leading-10 inline-block text-center mb-5">
              1
            </div>
            <p>
              Register on the Boxed portal below & sign in with your
              credentials.
            </p>
          </div>
          <div>
            <div className="bg-boxed rounded-full text-white font-bold text-xl h-10 w-10 leading-10 inline-block text-center mb-5">
              2
            </div>
            <p>View all PPE items, and add to your shopping cart.</p>
          </div>
          <div>
            <div className="bg-boxed rounded-full text-white font-bold text-xl h-10 w-10 leading-10 inline-block text-center mb-5">
              3
            </div>
            <p>10% discount will appear in your shopping cart upon purchase.</p>
          </div>
        </div>

        <OutboundLink
          className="btn bg-boxed text-white"
          href="https://boxed.com/njppeaccess"
          target="_blank"
          rel="nofollow noopener noreferrer"
        >
          Get Started
        </OutboundLink>
      </Modal>
    </Layout>
  )
}

export default IndexPage
